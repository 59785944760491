<!--
 * @Description: 车行道停车运营管理-路段管理-停车路段 onroadParkRoadNext
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-20 11:33:56
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <el-row class="dt-back">
      <i class="el-icon-back"
         :style="colorJson[theme].color"
         @click="handleBack"></i>
      <span class="backTitle"
            :style="colorJson[theme].color">路段泊位信息管理</span>
    </el-row>
    <div class="dt-firstLine-box">
      <div class="fristLine-left">
        <div class="dt-title">基础信息</div>
        <div class="line-left">
          <div class="fristLine-text">路段名称:{{parkInfo.parkName}}</div>
          <div class="fristLine-text">路段编号:{{parkInfo.parkId}}</div>
        </div>
        <div class="line-right">
          <div class="fristLine-text">路段地址:{{parkInfo.address}}</div>
          <div class="fristLine-text">总泊位数:{{parkInfo.parkSpaceNum}}</div>
        </div>
      </div>
      <div class="fristLine-right">
        <div class="dt-title"
             :style="colorJson[theme].color">车位信息</div>
        <div class="progress-bar">
          <progressBar :percent="percent"
                       :member="member"
                       :denominator="denominator"
                       lable="在停/泊位数"
                       color="#08F6E4" />
        </div>
      </div>
    </div>
    <el-row class="dt-thirdLineTitle"
            :style="colorJson[theme].color">路段数据</el-row>
    <el-row class="dt-thirdLine">
      <div class="box box1">
        <div class="box-title">今日停车车次</div>
        <div class="box-num">{{infoList.parkTimes?infoList.parkTimes:0}}</div>
      </div>
      <div class="box box2">
        <div class="box-title">停车周转率</div>
        <div class="box-num">{{infoList.turnoverRate}} <span style='font-size:16px'>次/车位</span></div>

      </div>
      <div class="box box3">
        <div class="box-title">停车利用率</div>
        <div class="box-num">{{infoList.usageRate}}%</div>
      </div>
      <div class="box box4">
        <div class="box-title">今日订单数</div>
        <div class="box-num">{{infoList.orderNumber?infoList.orderNumber:0}}</div>
      </div>
      <div class="box box5">
        <div class="box-title">今日已完成订单数</div>
        <div class="box-num">{{infoList.orderCompleteNumber?infoList.orderCompleteNumber:0}}</div>
      </div>
      <div class="box box6">
        <div class="box-title">非今日未完成订单数</div>
        <div class="box-num">{{infoList.orderUnCompleteNumber}}</div>
      </div>
    </el-row>
    <!-- 底部车位 -->
    <div class="berth-image"
         :style="'background:url('+colorJson[theme].back+')'">
      <div class="berth-row1">
        <div class="card-container"
             v-for="(item,idx) in topList"
             :key="idx">
          <!-- 在停 -->
          <div class="card-box">
            <div class="img-box">
              <div class="dt-car"
                   :style="item.plateNumber?'background:#e56965':'background:#34dca3'">
                <div v-if="item.plateNumber">
                  <div class="dt-plate-color">
                    {{ item.numberPlateColorName?item.numberPlateColorName:'' }}
                  </div>
                  <div class="dt-plate">{{ item.plateNumber }}</div>
                </div>
                <div v-else>
                  <span style="font-size:40px;font-weight:600">P</span>
                </div>
              </div>
            </div>
            <div class="card-title">车位编号:{{ item.parkSpaceNumber }}</div>
            <div class="card-title">入场时间: <span style="font-size: 12px">{{ item.entranceTime }}</span></div>
          </div>
        </div>
      </div>
      <div class="berth-row2">{{parkInfo.parkName}}</div>
      <div class="berth-row3">
        <div class="card-container"
             v-for="(item, index) in bottomList"
             :key="index">
          <div class="card-box">
            <div class="card-title">车位编号:{{ item.parkSpaceNumber }}</div>
            <div class="card-title">入场时间: <span style="font-size: 12px">{{ item.entranceTime }}</span></div>
            <div class="img-box">
              <div class="dt-car"
                   :style="item.plateNumber?'background:#e56965':'background:#34dca3'">
                <div v-if="item.plateNumber">
                  <div class="dt-plate-color">
                    {{ item.numberPlateColorName }}
                  </div>
                  <div class="dt-plate">
                    {{ item.plateNumber }}
                  </div>
                </div>
                <div v-else>
                  <span style="font-size:40px;font-weight:600">P</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <span class="total">共 {{total}} 个车位</span>
      <el-pagination class="pagination"
                     :page-size="pageSize"
                     :total="total"
                     layout="prev,pager,next"
                     :current-page="pageNum"
                     :background="true"
                     @current-change="getberth"></el-pagination>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如:组件，工具js，第三方插件js，json文件，图片文件等等）
//例如:import 《组件名称》 from '《组件路径》';
import progressBar from "./progressBar";
import { mapState } from 'vuex'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    progressBar
  },
  data () {
    //这里存放数据
    return {
      colorJson: {
        dark: {
          back: require("../../../../../assets/onroadParkRoadNext/roadbackground.png"),
          color: "color:white"
        },
        light: {
          back: require("../../../../../assets/onroadParkRoadNext/roadbackgroundWhite.png"),
          color: "color:black"
        }
      },
      pageSize: 12,
      pageNum: 1,
      pageNumOther: 1,
      total: 0,// 分页
      percent: 0,// 泊位进度条百分比
      member: 0, // 泊位进度条分子
      denominator: 0, // 泊位进度条分母
      infoList: {}, // 信息列表
      parkInfo: {}, // 停车场信息
      topList: [],
      bottomList: [],
      totalList: [],
      onlinelist: []
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.parkInfo = JSON.parse(this.$route.query.info)
    this.queryParkBaseInfo()
    this.getOnlineStatus()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 获取
    queryParkBaseInfo () {
      let info = {
        parkId: this.parkInfo.parkId
      }
      this.$onroadParkRoadNext.baseInfo(info).then(res => {
        this.infoList = res.resultEntity
        this.denominator = res.resultEntity.parkSpaceNumber
        this.member = res.resultEntity.occupiedNumber
        this.percent = (this.member / this.denominator) * 100
        this.infoList.turnoverRate = Number(this.infoList.turnoverRate).toFixed(2)
        this.infoList.usageRate = Number(this.infoList.usageRate).toFixed(2)
      })
    },
    // 线上状态
    getOnlineStatus () {
      let info = {
        parkId: this.parkInfo.parkId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.$onroadParkRoadNext.queryListByPage(info).then(res => {
        this.totalList = res.resultEntity.list
        this.total = res.resultEntity.total
        let info1 = {
          parkId: this.parkInfo.parkId,
        }
        this.$onroadParkRoadNext.getOrderOnlineNoPage(info1).then(response => {
          this.onlinelist = response.resultEntity
          this.onlinelist.forEach(item => {
            item.parkSpaceId = item.parkSpaceNumber
          })
          for (let j = 0; j < this.onlinelist.length; j++) {
            for (let i = 0; i < this.totalList.length; i++) {
              if (this.totalList[i].parkSpaceId === this.onlinelist[j].parkSpaceNumber) {
                this.totalList[i] = this.onlinelist[j]
              }
            }
          }
          this.totalList = [...this.totalList]
          let topList = [],
            bottomList = []
          this.totalList.forEach((item, idx) => {
            let json = {
              entranceTime: item.entranceTime,
              numberPlateColorCode: item.numberPlateColorCode,
              parkSpaceNumber: item.parkSpaceId,
              plateNumber: item.plateNumber,
              numberPlateColorName: item.plateColor,
              parkingstate: 1,
            }
            if (idx < 6) {
              topList.push(json)
            } else if (idx < 12) {
              bottomList.push(json);
            }
          });
          if (bottomList.length < 6) {
            for (let i = bottomList.length; i < 6; i++) {
              bottomList.push({});
            }
          }
          if (topList.length < 6) {
            for (let i = topList.length; i < 6; i++) {
              topList.push({});
            }
          }
          this.topList = topList;
          this.bottomList = bottomList;
        })
      })
    },
    // 获取泊位信息
    getberth (val) {
      this.pageNum = val
      this.getOnlineStatus()
    },
    handleBack () {
      window.history.back(-1);
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.mainbody {
  height: 100%;
  .dt-back {
    height: 3%;
    margin-left: 10px;
    line-height: 22px;
    font-weight: 500;
    font-size: 16px;
    color: #bae8ff;
    .backTitle {
      margin-left: 2px;
    }
  }
  .dt-firstLine-box {
    height: 13%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .fristLine-left {
    box-sizing: border-box;
    margin: 5px 5px 0px 10px;
    padding: 0px 0 0 15px;
    height: 110px;
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #70aee8;
    @include background('blockBg');
  }
  .fristLine-right {
    box-sizing: border-box;
    margin: 5px 10px 0px 5px;
    padding: 0px 0 0 15px;
    height: 110px;
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #70aee8;
    @include background('blockBg');
  }
  .dt-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #fff;
    line-height: 35px;
  }

  .line-left {
    float: left;
    width: 50%;
    margin: 0;
  }
  .line-right {
    float: left;
    width: 50%;
    margin: 0;
  }
  .fristLine-text {
    line-height: 35px;
  }
  .progress-bar {
    width: 97%;
    margin-top: 10px;
  }
  .dt-thirdLineTitle {
    height: 2%;
    margin-top: 16px;
    margin-left: 10px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #f8fdff;
    line-height: 22px;
  }
  .dt-thirdLine {
    height: 11%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    .box {
      width: 16%;
      height: 100%;
      background: green;
    }
    .box1 {
      background: linear-gradient(90deg, #1bc2e1 0%, #008fc3 100%);
    }
    .box2 {
      background: linear-gradient(90deg, #703de9 0%, #3654dd 100%);
    }
    .box3 {
      background: linear-gradient(90deg, #0a8ddb 0%, #1d53cb 100%);
    }
    .box4 {
      background: linear-gradient(90deg, #ed5686 0%, #e5774c 100%);
    }
    .box5 {
      background: linear-gradient(90deg, #5fa2d5 0%, #337def 100%);
    }
    .box6 {
      background: linear-gradient(90deg, #55ccaf 0%, #0cd273 100%);
    }
    .box-title {
      margin-left: 25px;
      margin-top: 10px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 40px;
    }
    .box-num {
      margin-left: 25px;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 40px;
    }
  }
  .berth-image {
    height: 57%;
    display: flex;
    flex-direction: column;
    margin: 16px 10px;
    padding: 10px 0;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    .berth-row1 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 33%;
    }
    .berth-row2 {
      width: 100%;
      height: 34%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include font_color('themefontcolor');
      font-size: 40px;
      font-weight: 600;
    }
    .berth-row3 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 33%;
    }
    .card-container {
      width: 16%;
      height: 100%;
      .card-box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
        .img-box {
          position: relative;
          width: 80%;
          height: 60%;
          background-image: url('../../../../../assets/onroadParkRoadNext/cardBorder.png');
          background-size: 100% 100%;
          .card-border {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #34dca3;
          }
          .dt-car {
            position: absolute;
            left: 3.5%;
            top: 8.5%;
            width: 93%;
            height: 83%;
            display: flex;
            justify-content: center;
            align-items: center;
            .dt-plate-color {
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 35px;
              text-align: center;
            }
            .dt-plate {
              font-size: 27px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #ffffff;
              line-height: 46px;
              text-align: center;
            }
          }
          .card {
            position: absolute;
            left: 38%;
            top: 16%;
            width: 23%;
            height: 68%;
          }
          .reversal {
            transform: rotateZ(180deg);
          }
        }
        .card-title {
          width: 80%;
          text-align: left;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #70aee8;
          line-height: 20px;
        }
      }
    }
  }
}
.total {
  margin-left: 10px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #70aee8;
  line-height: 30px;
}
.pagination {
  float: right;
  margin: 0px 10px;
}
</style>