<!--
 * @Description: 进度条组件
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-05-24 15:15:55
 * @LastEditors: zhoucheng
-->
<template>
  <div class='progressMain'>
    <span class="text">{{lable}}</span>
    <div class="progress-containe">
      <el-progress class="progress"
                   :stroke-width="30"
                   :percentage="percent"
                   :color="color"></el-progress>
      <el-image class="progress-cover"
                :src="require('@/assets/onroadParkRoadNext/progress.png' )"></el-image>
    </div>
    <span class="text">{{member}}/{{ denominator}}</span>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    percent: {
      type: Number,
      default: 0
    },
    member: {
      type: Number,
      default: 0
    },
    denominator: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#00FF00'
    },
    lable: {
      type: String,
      default: ''
    },
  },
  data () {
    //这里存放数据
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {

  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.progressMain {
  display: flex;
  flex-direction: row;
  align-items: canter;
  width: 100%;
  height: 30px;
  padding: 5px 0 5px 10px;
  border: #00ebfb 1px solid;
  overflow: hidden;
  .text {
    line-height: 30px;
  }
  .progress-containe {
    position: relative;
    width: 80%;
    margin-left: 5px;
    margin-right: 5px;
    .progress {
      position: absolute;
      width: 100%;
    }
    .progress-cover {
      position: absolute;
      top: -5px;
      width: 100%;
      height: 50px;
      background-size: 100% 100%;
    }
  }
}
/deep/.el-progress-bar__outer {
  border-radius: 0;
}
/deep/.el-progress-bar__inner {
  border-radius: 0;
}
/deep/.el-progress__text {
  display: none;
}
/deep/.el-progress-bar {
  padding-right: 0;
}
</style>